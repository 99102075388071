<template>
    <div class="container-fluid p-4">
        <div class="row">
            <div class="col-12">
                <DoubleTankView></DoubleTankView>
            </div>
        </div>
    </div>
</template>
<script>
import DoubleTankView from './doubleTankView.vue';
export default {
    searchUserList: {},
    name: 'DoubleTank',
    components: {
        DoubleTankView
    },
}
</script>